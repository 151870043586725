/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.popular-categories {
    margin-bottom: 60px;

    @include desktop {
        margin-bottom: 80px;
    }

    h2 {
        text-align: center;
        margin-bottom: 30px;
        min-height: 29px;

        @include desktop-xxl {
            min-height: 39px;
        }
    }

    .pagebuilder-column-group {
        .pagebuilder-column {
            width: auto !important;

            @include desktop {
                transition: $transition1;
                margin-bottom: 0;

                &:hover {
                    box-shadow: $box-shadow3;
                }
            }
        }

        img {
            @include mobile {
                min-width: 145px;
                min-height: 105px;
            }

            @include desktop-xxl {
                width: 310px;
                height: 225px !important;
            }
        }
    }

    .popular-category-description {
        background: var(--primary-dark-color);
        margin-top: -4px;

        @include mobile {
            min-height: 106px;
        }

        @include desktop {
            min-height: 106px;
        }

        .popular-category-title {
            color: var(--color-white);
            font-weight: $font-weight-medium;
            font-size: 15px;
            text-align: center;
            letter-spacing: $letter-spacing;
            line-height: 19px;

            &:first-child {
                font-size: 18px;
                letter-spacing: $letter-spacing3;
                line-height: 22px;
                padding: 25px 0 15px 0;

                @include desktop {
                    font-size: 20px;
                }
            }

            a {
                font-weight: $font-weight-medium;
                color: var(--color-white);

                &:hover {
                    @include desktop {
                        color: var(--link-color);
                    }
                }
            }

            &:last-child {
                padding-bottom: 25px;
            }
        }
    }
}
