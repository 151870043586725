/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Router {
    &-MainItems {
        min-height: calc(100vh - var(--header-total-height) + 10px);

        main {
            // &.CategoryPage {
            //     .popular-categories {
            //         .pagebuilder-column-group {
            //             display: grid !important;
            //             grid-template-columns: repeat(2, 1fr);
            //             grid-auto-rows: max-content;
            //             grid-gap: 10px;
            //             padding: 0 10px;
                
            //             @include mobile {
            //                 min-height: 430px;
            //             }
                
            //             @include desktop {
            //                 padding: 0;
            //                 grid-template-columns: repeat(4, 1fr);
            //                 grid-gap: 20px;
            //             }
                
            //             @include desktop-xxl {
            //                 min-height: 330px;
            //             }
            //         }
            //     }
            // }

            &.CmsPage,
            &.CategoryPage {
                .popular-categories {
                    .pagebuilder-column-group {
                        @include mobile {
                            flex-wrap: wrap;
                        }

                        .pagebuilder-column-line {
                            display: grid !important;
                            grid-template-columns: repeat(2, 1fr);
                            grid-auto-rows: max-content;
                            grid-gap: 10px;
                            padding: 0 10px;
                    
                            @include mobile {
                                min-height: 430px;
                            }
                    
                            @include desktop {
                                padding: 0;
                                grid-template-columns: repeat(4, 1fr);
                                grid-gap: 20px;
                            }
                    
                            @include desktop-xxl {
                                min-height: 330px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-Loader {
        height: calc(100vh - var(--header-total-height) - 10px);
        inset-block-start: calc(var(--header-total-height) + 10px);
    }
}
